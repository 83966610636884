<template>
  <div style="background-color: #f5faff">
    <NavBar style="max-height:50px;margin-bottom: 10px;" 
     :username="currentUserName"
    :pageName="pageName"></NavBar>

    <v-app >
      <!-- Cancal appointment Dialog Start  -->
       <!-- <div class="hello">
      <div id="video-container" class="remote_video_container">
      <div id="localTrack"></div>
      <div id="remoteTrack"></div>
        </div>
    </div> -->
      <div>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="300">
            <v-card>
              <v-card-title style="word-break: normal; padding: 5%">
                Are you sure you want cancel Appointment
                <p style="font-weight: bold; margin: 0% 0% 0% 40%">
                  {{ book_id }}
                </p>
                ?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                color="primary"
                @click="confirm(appointmentid)"
                >
                Yes
              </v-btn>
              <v-btn color="error" @click="notConfirm">
                No
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <div>
      <v-row justify="center">
        <v-dialog v-model="paymentIDModal" persistent width="732">
          <v-card>
            <v-card-title style="font-size:18px; color:#1467BF; dispaly:flex; flex-direction:row; justify-content: space-between;">
                <div> Payment ID </div>
                <div>
                  <span @click="paymentIDModal = false" style="color: red"> <img src="@/assets/popupClose.svg" > </span>
                  </div>
            </v-card-title>
            <div>
              <v-divider></v-divider>
            </div>
            <v-card-text>
              <div align="left">
                <p style="font-weight: 500;font-size: 17px;line-height: 21px; color:black">Payemnt ID</p>
              </div>
              <div>
              <v-text-field
                outlined
                label="Enter Payment ID"
                v-model="paymentID"
                >
                </v-text-field>
              </div>
            </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
              color="primary"
              @click="updatePaymentID"
              >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    </div>
    <div  style="background-color: #f5faff; height: 93vh;">
      <v-row cols="12">
        <v-col cols="2">
        <SideBar style="max-height: 50px;" :pageName="pageName"></SideBar>
        </v-col>
        <v-col cols="10" style="overflow:scroll; height:90vh">
          <div style="margin: 10px 20px 20px 50px ">
            <div
              style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              vertical-align: middle;
              "
              >
              <div style="width: 30%" align="left">
                <span style="font-weight: 800; padding-left: 8px;  font-size: 12pt;
                  text-transform: uppercase;   letter-spacing: 1px;">
                  <h3>View All Appointment</h3>
                </span>
              </div>
              <div style="display: flex">
                <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="isDownloadClicked"
                    ></v-progress-circular>
                <v-btn
                    :disabled = "isDownloadClicked"
                    depressed
                    color="primary"
                    style="margin:18px 10px; height:39px"
                    @click="downloadIniatiator"
                  > Download
                  </v-btn>
                 <v-select
                  v-model="search_type"
                  :items="items"
                  :menu-props="{ top: true, offsetY: true }"
                  placeholder="Search With"
                  solo
                  height="48px"
                  dense
                  @change="start_date = '',end_date = '', phone_number = '', nrc_number= ''"
                  style="width: 30%; margin-right: 5px;z-index: 8; margin-top:18px"
                  @input="followUp"
                  ></v-select>
                <div style="display: flex; flex-direction: row;margin-top:18px" v-if="search_type=='Date'">
                  <div>
                    <p style="margin-top: 5px; font-weight: bold">From:</p>
                  </div>
                  <div>
                    <input
                    type="date"
                    v-model="start_date"
                     @change="phone_number = '', nrc_number= ''"
                    style="
                    margin-top: 3px;
                    background-color: white;
                    width: 90%;
                    padding: 3%;
                    box-shadow: 0px 8px 30px 0px #0547891a;
                    color: #1467bf;
                    border-radius: 5px;
                    "
                    />
                  </div>
                </div>
                <div style="display: flex; flex-direction: row;margin-top:18px" v-if="search_type=='Date'">
                  <div>
                    <p style="margin-top: 5px; font-weight: bold">To:</p>
                  </div>
                  <div>
                    <input
                    type="date"
                    v-model="end_date"
                    style="
                    margin-top: 3px;
                    background-color: white;
                    width: 90%;
                    padding: 3%;
                    box-shadow: 0px 8px 30px 0px #0547891a;
                    color: #1467bf;
                    border-radius: 5px;
                    "
                    @change="phone_number = '', nrc_number= ''"
                    />
                  </div>
                  <div v-if="start_date && end_date">
                    <a>
                      <img
                      @click="filterWithDateRange"
                      style="margin-top: 5px; height: 2em"
                      src="https://s3iconimages.mymedicine.com.mm/search.svg"
                      /></a>
                  </div>
                </div>
                <div
                  style="
                  display: flex;
                  justify-content: flex-end;
                  margin: 17px 5px;
                  margin-top: 3 %;
                  "
                  >
                  <v-select
                  class="appointmentStatus"
                  v-if="search_type == 'Appointment Status'"
                  v-model="appointmentStatusFilterValue"
                  :items="appointmentStatusList"
                  :menu-props="{ top: true, offsetY: true }"
                  placeholder="Select the Appointment Status"
                  solo
                  @change="paymentStatusFilterValue=''"
                  @input="filterAppointment"
                  ></v-select>

                  <v-select
                   v-if="search_type == 'Payment Status'"
                  v-model="paymentStatusFilterValue"
                  :items="paymentStatusList"
                  item-text="Value"
                  item-value="Key"
                  :menu-props="{ top: true, offsetY: true }"
                  placeholder="Select the  Payment Status"
                  solo
                  @change="appointmentStatusFilterValue=''"
                  @input="filterAppointment"
                  ></v-select>
                  <v-text-field
                  v-if="search_type == 'NRC'"
                  v-model="nrc_number"
                  label="Enter NRC number"
                  dense
                  solo
                  style="width: 50%"
                  >
                  <template v-slot:append>
                    <v-btn @click="filterAppointment" icon>
                      <img
                      style="width: 25px; height: 25px"
                      src="https://s3iconimages.mymedicine.com.mm/search.svg"
                      />
                    </v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                v-if="search_type == 'Phone Number'"
                v-model="phone_number"
                placeholder="Type here"
                label="Enter Phone number"
                :rules="mobileNumberRules"
                dense
                solo
                style="width: 50%;margin:7px 0px;"
                ><template v-slot:append>
                  <v-btn @click="filterAppointment" icon>
                    <img
                    style="width: 25px; height: 25px"
                    src="https://s3iconimages.mymedicine.com.mm/search.svg"
                    />
                  </v-btn>
                </template>
                  </v-text-field>
                  <v-text-field
                  v-else-if="search_type != ''"
                  v-show="false"
                  ></v-text-field>
                </div>
              </div>
            </div>
      <div style="margin-top: 1%; overflow: scroll; overflow-x: hidden; height: 45px 0px" v-if="!isLoading">
        <table id="appointments_table" class="tableClass" v-if="allAppointments.length > 0" >
          <tr>
            <!-- Booking ID, Patient Name, Phone Number, KBZ Employee, Date, Time, Specialization, Doctor Name, Appointment Status, Payment Status, Actions -->
            <th  align="left" style="padding-left:8px">Ref. No.</th>
            <th align="left" style="font: size 0.5em;">Patient Name</th>
            <th align="left" style="font: size 0.5em;">Phone No.</th>
            <!-- <th align="left" style="font: size 0.5em;">NRC</th> -->
            <th align="left" style="font: size 0.5em;">Corporate Emp.</th>
            <th align="left" style="font: size 0.5em;">Date</th>
            <th align="left" style="font: size 0.5em;">Time Slot</th>
            <th align="left" style="font: size 0.5em;">Specialization</th>
            <th align="left" style="font: size 0.5em;">Doctor Name</th>
            <th align="left" style="font: size 0.5em;">Offer Type</th>
            <th align="left" style="font: size 0.5em;">Appointment Status</th>
            <th align="left" style="font: size 0.5em;">Doctor Status</th>
            <th align="left" style="font: size 0.5em;">Customer Status</th>

            <th align="center" style="font: size 0.5em;">Payment Status</th>
            <th align="left" style="font: size 0.5em;">Actions</th>
          </tr>
          <tr v-for="appointment in allAppointments" :key="appointment._id">
            <td  style="font: size 0.5em; padding-left:8px" align="left">
              {{ appointment.appointment_reference_number }}
            </td>
            <td  style="font: size 0.5em;" align="left">
              {{ appointment.booked_for_name }}
            </td>
            <td  style="font: size 0.5em;" align="left">
              {{ appointment.phoneNumber }}
            </td>
            <!-- <td  style="font: size 0.5em;" align="left">
              {{ appointment.NRC }}
            </td> -->
            <td  style="font: size 0.5em;" align="left" v-if="appointment.corporate_employee_flag">
              Yes
            </td>
             <td  style="font: size 0.5em;" align="left" v-else>
              No
            </td>
            <td  style="font: size 0.5em;" align="left">
              <DateRendered :dateVal="appointment.date_of_appointment"> </DateRendered>
            </td>
            <td  style="font: size 0.5em; " align="left">
              <TimeRender :timeSlot="appointment.time_slot"></TimeRender>
            </td>
             <td   style="font: size 0.5em;" align="left">
              {{ appointment.specialization_name }}
            </td>
             <td   style="font: size 0.5em;" align="left">
              {{ appointment.doctor_name }}
            </td>
            <td style="font: size 0.5em;" align="left">
              {{ !appointment.offer_type ? '-' : appointment.offer_type }}
            </td>
            <td   style="font: size 0.5em;" align="left">
              <admin-home-appointment-status :appointmentDate="appointment.date_of_appointment" :timeSlot="appointment.time_slot" :status="appointment.status"/>
            </td>
            <td   style="font: size 0.5em;" align="left">
              {{ appointment.doctor_join_status }}
            </td><td   style="font: size 0.5em;" align="left">
              {{ appointment.customer_join_status }}
            </td>
            <td  style="font: size 0.5em;" align="center">
              <div v-if="appointment.created_by == 'ADMIN' && appointment.payment_status == 'PAYMENT PENDING'">
                <v-btn color="primary" width="90px" height="30px" @click="openPaymentIDModal(appointment._id)">Pending</v-btn>
              </div>
              <div v-else>
                {{ appointment.payment_status }}
              </div>
            </td>
            <td>
             <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a style="width: 100%" icon @click="viewParticularAppointment(appointment._id)" depressed v-on="on" class="editAppointment" >
                    <img src="https://s3iconimages.mymedicine.com.mm/show_pwd.svg" style="height: 24px; width: 24px;"/>
                  </a>
                </template>
                <span>View</span>
              </v-tooltip>
              <v-tooltip v-if="appointment.rescheduleCheck" bottom>
                <template v-slot:activator="{ on }">
                  <a style="width: 100%; margin: 5px" icon @click="rescheduleAppointment(appointment._id)" depressed v-on="on" class="rescheduleAppointment"
                  >
                  <img
                    src="https://s3iconimages.mymedicine.com.mm/refresh.svg"
                  />
                <!-- <span class="rescheduleClass">Reschedule </span> -->
                  </a>
                </template>
                <span>Reschedule</span>
              </v-tooltip>
              <v-tooltip v-if="appointment.meeting_link && appointment.status == 'ACTIVE'" bottom>
                <template v-slot:activator="{ on }">
                  <a
                style="width: 100%; margin:5px;"
                icon
                @click="JoinLink(appointment._id)"
                depressed
                v-on="on"
                class="joinAppointment"
                  >
                <img
                    src="https://s3iconimages.mymedicine.com.mm/join_call.svg" class="video_join_class" style="height: 24px; width: 24px;"
                  />
                  </a>
                </template>
                <span>Join</span>
              </v-tooltip>
              <v-tooltip v-if="appointment.allowedEdit" bottom>
                <template v-slot:activator="{ on }">
                  <a
                style="width: 100%"
                icon
                v-on="on"
                @click="
                  cancelAppointment(appointment._id, appointment.appointment_reference_number)"
                depressed
                class="cancelAppointment"
                  >
                    <img
                      src="https://s3iconimages.mymedicine.com.mm/delete.svg"
                    />
                  </a>
                </template>
                <span>Cancel</span>
              </v-tooltip>
              <v-tooltip v-if="appointment.followup_date && appointment.followup_date != 'Not Required'" bottom>
                <template v-slot:activator="{ on }">
                  <a
                style="width: 100%; padding-right: 10px;"
                icon
                v-on="on"
                depressed
                  >
                    <img
                      src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                    />
                  </a>
                </template>
                <span>TCA: {{appointment.followup_date}}</span>
              </v-tooltip>
              <!-- commented for hide verify devices -->
              <!-- <img style="height: 40px" align="right" src="https://s3iconimages.mymedicine.com.mm/unTested.svg" v-if="appointment.class=='Unverified'">
              <img style="height: 40px" align="right" src="https://s3iconimages.mymedicine.com.mm/verifiedVideoCall.svg" v-if="appointment.class=='verified'">
              <img style="height: 40px" align="right" src="https://s3iconimages.mymedicine.com.mm/inProgress.svg" v-if="appointment.class=='InProgress'">
              <img style="height: 40px" align="right" src="https://s3iconimages.mymedicine.com.mm/unVerifiedVideoCall.svg" v-if="appointment.class=='NeedAssistance'"> -->
            </td>
          </tr>
        </table>
        <div v-else>
          <p>Sorry, No matching results found. </p>
        </div>
        <div v-if="pagination_list > 1">
          <v-pagination circle v-model="page" :length="pagination_list" :total-visible="7" @input="findPage(page)" ></v-pagination>
        </div>
      </div>
      <v-progress-circular
      indeterminate
      color="primary"
      v-else
      ></v-progress-circular>
    </div>
</v-col>
</v-row>
</div>
</v-app>
</div>
</template>
<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import DateRendered from "../Customer/dateRender.vue"
import TimeRender from '../Customer/TimeRender.vue';
import { type } from 'os';
const Twilio = require("twilio-video");

import AdminHomeAppointmentStatus from '../../../components/adminHomeAppointmentStatus.vue';
const EXCEL = require('excel4node');
export default {
  name: "AdminHome",
  data() {
    return {
      start_date: "",
      page:1,
      pageNum:"",
      end_date: "",
      allAppointments: "",
      pageName: "",
      currentUser: "",
      currentUserName: "",
      items: ["Phone Number", "NRC","Date","Follow Ups", "Appointment Status", "Payment Status"],
      appointmentStatusList:["Active", "In Progress", "Booked and Await Payment", "Cancelled","Rescheduled","Completed", "Failed", "Abandoned"],
      paymentStatusList:[{"Key":"PAID", "Value":"Paid"},{"Key":"PAYMENT PENDING", "Value":"Payment Pending"},{"Key":"REFUND_PARTIAL", "Value":"Refund Partial"},{"Key":"REFUND_FULL", "Value":"Refund Full"},{"Key":"FAILED", "Value":"Failed"},{"Key":"EXPIRED", "Value":"Expired"}],
      appointmentStatusFilterValue:"",
      paymentStatusFilterValue:"",
      nrc_number: "",
      phone_number: "",
      search_type: "",
      dialog: false,
      viewAppointmentDialog: false,
      appointmentid: "",
      book_id: "",
      nominee: "",
      enquiry_name: "",
      phoneNumberofPatient: "",
      amount: "",
      nrcNumber: "",
      nameOfDoctor: "",
      specializationOfDoctor: "",
      payment_status: "",
      flag: 0,
      time_slot: "",
      pagination_list:"",
      date: "",
      description: "",
      mobileNumberRules: [ (v) => !!v || 'Mobile Number is required',(v) => v > 0 || "Mobile number cannot be negative"],
      isLoading : true,
      rescheduleCheck: false,
      current_nrc: "",
      current_phone_number: "",
      current_start_date: "",
      current_end_date: "",
      followUpFlag:false,
      followUpDate:"",
      downloadData:[],
      isDownloadClicked: false,
      appointmentCreatedBy:"",
      paymentIDModal:false,
      paymentID:"",
      bookingIDPaymentUpdate:"",
      remoteParticipant: null,
      activeRoom: null,
      container: null,
      activeRoom: null,
      callConnected: null,
      deviceIDList: [],
      videoConstraint: null,
      ConnectTrack: null,
      currentFacingMode: null,
      twilioRoomName: null,
      twilioMeetLink: null,
      customer_name: null,

    };
  },
  async mounted() {
    this.pageName = "AdminHome";
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      this.start_date = new Date().toISOString().substr(0, 10);
      var newDate = new Date();
      newDate.setDate(newDate.getDate() + 7);
      this.end_date = newDate.toISOString().substr(0, 10);
      var data = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        fromDate : this.start_date,
        toDate : this.end_date,
        page : 1,
        limit : 10
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/adminHomePage", data)
      .then((adminHomeResponse) => {
        if(adminHomeResponse.status == 200){
          this.allAppointments = adminHomeResponse.data.data.docs;
          // console.log("All Appointment Data", this.allAppointments);
          this.pagination_list = adminHomeResponse.data.data.pages;
          this.page = 1;
          this.currentUserName = adminHomeResponse.data.admin_data.admin_name;
          this.isLoading = false
          this.allAppointments.forEach((x) =>{
              x.rescheduleCheck = ((x.status == "ACTIVE") && (x.rescheduled_appointment_id == '')) ? true : false;
              var dateNow = new Date();
              var getDifference = new Date(x.booking_epoch_time).getTime() - dateNow.getTime();
              var booking_date = new Date().toISOString().split("T")[0];
              x.allowedEdit = ((x.date_of_appointment > booking_date) && (x.status == "ACTIVE")) ? true : false;
              // x.class = "verified";
              x.class = x.configStatus
            });
          this.current_start_date = this.start_date;
          this.current_end_date = this.end_date;
          this.current_nrc = this.nrc_number;
          this.current_phone_number = this.phone_number;
          }
      })
      .catch((adminHomeError) => {
        console.log(adminHomeError);
        if (adminHomeError.response.status == 401) {
          window.alert("Sorry !! You are unauthorized !!");
          this.$router.push({
            name: "AdminLogin",
          });
        } else {
          window.alert("Something went wrong...");
        }
      })
    
    }
  },
  components: {
    NavBar,
    DateRendered,
    TimeRender,
    SideBar,
    AdminHomeAppointmentStatus
  },
  methods: {
          async JoinLink(meeting_link)
          {
            this.$router.push({
              'name': 'JoinVideoCallAdminTwilio',
              params: {
                booking_id: meeting_link
              }
            })
          },
          downloadIniatiator(){
            this.isDownloadClicked = true;
            var appointmentStatus = this.appointmentStatusFilterValue.toUpperCase();
            const appointment_body ={
              token: this.currentUser,
              typeOfUser: "ADMIN",
              NRC: this.nrc_number,
              phoneNumber: this.phone_number,
              appointmentStatus: appointmentStatus,
              paymentStatus:this.paymentStatusFilterValue,
            }

            if(this.search_type == "Date")
            {
              appointment_body.fromDate=this.start_date
              appointment_body.toDate=this.end_date
              //  console.log("[date]",this.start_date,this.end_date);
            }
            else if(this.search_type == "Follow Ups")
            {
              appointment_body.followUpFlag = true
            }
        axios
          .post(
          process.env.VUE_APP_BACKEND_URL + "/readAppointmentsWithoutPagination", appointment_body)
          .then((viewCurrentDateResponse) => {
            // console.log(viewCurrentDateResponse);
          var rawDownloadData = viewCurrentDateResponse.data.data;
        // console.log("rawDownloadData : ", rawDownloadData);

          this.downloadData = [];

          rawDownloadData.forEach((x) =>{
            let age;
            if(x.booked_by_id == x.booked_for_id){
              if(x.parentInfo && x.parentInfo.customer_dob){
                // console.log("dob : ", x.parentInfo[0].customer_dob);
                // console.log("dob split : ", x.parentInfo[0].customer_dob.split("T")[0]);
                let dob = new Date(x.parentInfo.customer_dob.split("T")[0]);
                let today = new Date();
                age = (today.getFullYear() - dob.getFullYear());
              }
            }
            else if(x.booked_by_id != x.booked_for_id){
              if(x.dependentInfo && x.dependentInfo.dependent_dob){
                // console.log("dob : ", x.dependentInfo[0].dependent_dob);
                // console.log("dob split : ", x.dependentInfo[0].dependent_dob.split("T")[0]);
                let dob = new Date(x.dependentInfo.dependent_dob.split("T")[0]);
                let today = new Date();
                age = (today.getFullYear() - dob.getFullYear());
              }
            }
            var obj = {
              "Reference Number" : x.appointment_reference_number,
              "Patient Name" : x.booked_for_name,
              "Patient Age" : (age >= 0) ? ((age == 0) ? "0" : age) : "0",
              "Patient Gender" : (x.dependentInfo) ? x.dependentInfo.dependent_gender : (x.parentInfo ? x.parentInfo.customer_gender : ""),
              "Phone Number" : x.phoneNumber,
              // "KBZ Employee": x.KBZCheck ? (x.KBZCheck.kbzEmployee === true ? "YES" : "NO") : "NO",
              // "Linked User Company" : (!x.customerMappingInfo) ? "" : x.customerMappingInfo.customer_mapping_company_name,
              // "Linked User Employee ID" : (!x.customerMappingInfo) ? "" : x.customerMappingInfo.customer_mapping_staff_id,
              "NRC" : x.NRC,
              "Doctor Name" : x.doctor_name,
              "Doctor Specialization" : x.specialization_name,
              "Date Of Booking" : x.createdAt.split("T")[0],
              "Date Of Appointment" : x.date_of_appointment,
              "Time Slot" : x.time_slot,
              "Type Of Appointment": x.type_of_appointment ? x.type_of_appointment : '-',
              "Appointment Status" : x.status,
              "Payment Status" : x.payment_status,
              "No-show Status" : (x.no_show == 'NA' || x.no_show == '-' || x.no_show == 'NONE') ? '-' : x.no_show,
              "Amount" : (!x.amount) ? String(0) : String(x.amount),
              "Doctor Fee" : x.service_fee ? x.service_fee : (x.amount > 0 && x.commission > 0) ? (x.amount - x.commission) : String(0),
              "Commission Fee" : x.commission ? x.commission : (x.amount > 0 && x.service_fee > 0) ? (x.amount - x.service_fee) : String(0),
              "Promotion Type": (!x.offer_type) ? "" : x.offer_type,
              "Promotion Detail" : x.offer_type ? (x.offer_type == "COUPON" ? x.offer_reference_code.substr(0, (x.offer_reference_code.length-5)) : (x.offer_type == "PROMOTION" ? x.offer_reference_code : (x.offer_type == "CORPORATE" ? x.corporatePackageInfo.corporate_program_name : ""))) : "",
              "KBZPay payment ID" : (!x.payment_reference_number) ? "" : x.payment_reference_number,
              "Reason For Visit" : x.description.trim().toString(),
              "Diagnosis" : (x.prescriptionDetails && x.prescriptionDetails.diagnosed_with) ? (x.prescriptionDetails.diagnosed_with.trim()) : "",
              "Created By" : x.created_by,
              "Follow Up date" : (!x.followup_date) ? "" : x.followup_date,
              "Patient Type" : (!x.customerMappingInfo || !x.customerMappingInfo.customer_mapping_name ? ((x.booked_by_id != x.booked_for_id) ? 'Dependent' : 'Customer') : 'Linked'),
              "Coporate Main User Name" : (x.employeeInfo ? x.employeeInfo.name : ""),
              "Corporate Main User Phone" : (x.employeeInfo ? x.employeeInfo.phone_number: ""),
              "Corporate Company Name" : (x.employeeInfo ? (x.corporateInfo ? x.corporateInfo.company_name : "" ) : ""),
              "Corporate Employee ID" : (x.employeeInfo ? x.employeeInfo.employee_id : ""),
              "Feedback Rating": (x.feedbackInfo.length > 0) ? ((x.feedbackInfo[x.feedbackInfo.length -1].rating > 0) ? (x.feedbackInfo[x.feedbackInfo.length -1].rating > 10 ? 10 : x.feedbackInfo[x.feedbackInfo.length -1].rating) : 0) : "-",
              "Feedback Review": (x.feedbackInfo.length > 0) ? x.feedbackInfo[x.feedbackInfo.length-1].feedback : "-"
              // "Dependent Of" : (x.booked_by_id != x.booked_for_id) ? x.booked_by_name : '-',
              // "Customer Number" : (x.parentInfo && x.booked_by_id != x.booked_for_id) ? x.parentInfo.phoneNumber : "-",
            };
            this.downloadData.push(obj);
          });
          this.downloadCSV();
          })
          .catch((viewCurrentDateError) => {
            console.log("error : ", viewCurrentDateError);
            this.isDownloadClicked = false;
            if (viewCurrentDateError && viewCurrentDateError.response.status == 401) {
              window.alert("Sorry !! You are unauthorized !!");
              this.$router.push({
                name: "AdminLogin",
              });
            } else if(viewCurrentDateError.response.status == 400)
            {
              window.alert("Something went wrong with the database...");
            }
            else
            {
              window.alert("Something went wrong...");
            }
          });
          },
          convertArrayOfObjectsToCSV(args) {
            var result, ctr, keys, columnDelimiter, lineDelimiter, data, temp;
            data = args.data || null;
            columnDelimiter = args.columnDelimiter || '|';
            // console.log("columnDelimiter",columnDelimiter)
            lineDelimiter = args.lineDelimiter || '\n';
            // console.log("lineDelimiter",lineDelimiter)
            keys = Object.keys(data[0]);
            // console.log("Keys", keys)
            result = '"sep=|"\r\n';
            result += keys.join(columnDelimiter);
            result += lineDelimiter;
            data.forEach(function(item){
                ctr = 0;
                keys.forEach(function(key){
                    if (ctr > 0) result += columnDelimiter;
                    temp = (item[key] ? item[key].toString().replace(/(\r\n|\n|\r|\s+|\t|&nbsp;)/gm,' ') : "");
                    result += temp;
                    ctr++;
                });
                result += lineDelimiter;
            });
            return result;
          },
          downloadCSV() {
            var data, filename, link, filenameFormation;
            if(this.search_type == '' ){
              filenameFormation = "Details_"+"AllAppointments"
            }
            else if(this.search_type == "Date") {
              filenameFormation = "Details_"+this.search_type+"_"+this.start_date+"_"+this.end_date
            }
            else if(this.search_type == "NRC") {
              filenameFormation = "Details_"+this.search_type+"_"+this.nrc_number
            }
            else if(this.search_type == "Phone Number") {
              filenameFormation = "Details_"+"Phone_Number"+"_"+this.phone_number
            }
            else if(this.search_type == "Follow Ups") {
              filenameFormation = "Details_"+"Follow_Ups"
            }
            else if(this.search_type == "Appointment Status") {
              filenameFormation = "Details_"+"Appointment_Status"+this.appointmentStatusFilterValue
            }
            else if(this.search_type == "Payment Status") {
              filenameFormation = "Details_"+"Payment_Status"+this.paymentStatusFilterValue
            }

            // var workbook = new EXCEL.Workbook();

            // var worksheet = workbook.addWorksheet(filenameFormation);

            // let keys = Object.keys(data[0]);
            // // console.log("keys : ", keys);
            // for(let i = 0; i < keys.length; i++){
            //   worksheet.cell(1, i+1).string(keys[i]);
            //   // console.log(i);
            // }

            // workbook.write('excel.xlsx', function(error, success){
            //   if(error){
            //     console.log("error : ", error);
            //   }
            //   else if(success){
            //     console.log("success : ",success);
            //   }
            // });

            var csv = this.convertArrayOfObjectsToCSV({
                data: this.downloadData
            });
            if (csv == null) return;
            filename = filenameFormation || 'export.csv';
            if (!csv.match(/^data:text\/csv/i)) {
                csv = 'data:text/csv;charset=utf-8,' + csv;
            }
            data = encodeURI(csv);
            link = document.createElement('a');
            link.setAttribute('href', data);
            link.setAttribute('download', filename);
            link.click();
            this.isDownloadClicked = false;
          },
          followUp(){
            if(this.search_type == "Follow Ups")
            {
              this.followUpFlag = true;
              this.isLoading = true
              var appointment_body = {
                token: this.currentUser,
                typeOfUser: "ADMIN",
                page:1,
                limit: 10,
              };
              axios
              .post(
                process.env.VUE_APP_BACKEND_URL + "/filterAppointmentsByFollowUp",
                appointment_body
                )
              .then((filterAppointmentsByFollowUpResponse) => {
                this.allAppointments = filterAppointmentsByFollowUpResponse.data.data.docs;
                this.pagination_list = filterAppointmentsByFollowUpResponse.data.data.pages;
                this.page = 1;
                this.isLoading = false
                this.allAppointments.forEach((x) =>{
                  x.rescheduleCheck = ((x.status == "ACTIVE") && (x.rescheduled_appointment_id == '')) ? true : false;
                  var dateNow = new Date();
                  var booking_date = new Date().toISOString().split("T")[0];
                  x.allowedEdit = ((x.date_of_appointment > booking_date) && (x.status == "ACTIVE")) ? true : false;
                  x.class = x.configStatus
                });
                this.current_start_date = this.start_date;
                this.current_end_date = this.end_date;
                this.current_nrc = this.nrc_number;
                this.current_phone_number = this.phone_number;
              })
              .catch((filterAppointmentsByFollowUpError) => {
                if (filterAppointmentsByFollowUpError.response.status == 401) {
                  window.alert("Sorry !! You are unauthorized !!");
                  this.$router.push({
                    name: "AdminLogin",
                  });
                } else {
                  window.alert("Something went wrong...");
                }
              });
            }
          },
          findPage(pageNum) {
            this.isLoading = true;
            var api_url = "/filterAppointments";
            var getPageData = {};
            if(!this.followUpFlag)
            {
              api_url = "/filterAppointments";
              var appointmentStatus = this.appointmentStatusFilterValue.toUpperCase();
              getPageData = {
                fromDate: this.current_start_date,
                toDate: this.current_end_date,
                token: this.currentUser,
                typeOfUser: "ADMIN",
                appointmentStatus: appointmentStatus,
                paymentStatus:this.paymentStatusFilterValue,
                page: pageNum,
                limit: 10,
                NRC: this.current_nrc,
                phoneNumber: this.current_phone_number,
              };
            }
            else
            {
              api_url = "/filterAppointmentsByFollowUp"
              getPageData = {
                token: this.currentUser,
                typeOfUser: "ADMIN",
                page:pageNum,
                limit: 10,
              };
            }
            axios
            .post(
              process.env.VUE_APP_BACKEND_URL + api_url,
              getPageData
              )
            .then((viewCurrentDateResponse) => {
              this.allAppointments = viewCurrentDateResponse.data.data.docs;
              this.pagination_list = viewCurrentDateResponse.data.data.pages;
              this.isLoading = false;
              this.allAppointments.forEach((x) =>{
                x.rescheduleCheck = ((x.status == "ACTIVE") && (x.rescheduled_appointment_id == '')) ? true : false;
                var dateNow = new Date();
                var booking_date = new Date().toISOString().split("T")[0];
                x.allowedEdit = ((x.date_of_appointment > booking_date) && (x.status == "ACTIVE")) ? true : false;
                x.class = x.configStatus
              });
              this.current_start_date = this.current_start_date;
              this.current_end_date = this.current_end_date;
              this.current_nrc = this.current_nrc;
              this.current_phone_number = this.current_phone_number;
            })
            .catch((viewCurrentDateError) => {
              if (viewCurrentDateError.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$router.push({
                  name: "AdminLogin",
                });
              }
              else if (viewCurrentDateError.response.status == 404)
              {
                window.alert("Customer not found with phone number : " + this.current_phone_number);
              }
              else if(viewCurrentDateError.response.status == 400)
              {
                window.alert("Something went wrong with the database...");
              }
              else
              {
                window.alert("Something went wrong...");
              }
            });
          },
          filterWithDateRange() {
            this.isLoading = true
            this.followUpFlag = false;
            var appointment_body = {
              fromDate: this.start_date,
              toDate: this.end_date,
              token: this.currentUser,
              typeOfUser: "ADMIN",
              page:1,
              limit: 10,
            };
            axios
            .post(
              process.env.VUE_APP_BACKEND_URL + "/filterAppointments",
              appointment_body
              )
            .then((viewCurrentDateResponse) => {
              this.allAppointments = viewCurrentDateResponse.data.data.docs;
              this.pagination_list = viewCurrentDateResponse.data.data.pages;
              this.page = 1;
              this.isLoading = false
              this.allAppointments.forEach((x) =>{
                  x.rescheduleCheck = ((x.status == "ACTIVE") && (x.rescheduled_appointment_id == '')) ? true : false;
                  var dateNow = new Date();
                  var booking_date = new Date().toISOString().split("T")[0];
                  x.allowedEdit = ((x.date_of_appointment > booking_date) && (x.status == "ACTIVE")) ? true : false;
                  x.class = x.configStatus
                });
              this.current_start_date = this.start_date;
              this.current_end_date = this.end_date;
              this.current_nrc = this.nrc_number;
              this.current_phone_number = this.phone_number;
            })
            .catch((viewCurrentDateError) => {
              if (viewCurrentDateError.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$router.push({
                  name: "AdminLogin",
                });
              }
              else if (viewCurrentDateError.response.status == 404)
              {
                window.alert("Customer not found with phone number : " + this.current_phone_number);
                this.$router.go();
              }
              else {
                window.alert("Something went wrong...");
              }
            });
          },
          closeDialog() {
            this.viewAppointmentDialog = false;
          },
          viewParticularAppointment(booking_id) {
            this.booking_id = booking_id;
            let routeData = this.$router.push({
              path: "/admin/viewParticularAppointment/" + booking_id,
            });
          },
          notConfirm() {
            this.dialog = false;
          },
          confirm(appointment_id) {
            this.dialog = false;

            var appointment_body = {
              appointmentId: this.appointmentid,
              token: this.currentUser,
              typeOfUser: "ADMIN",
            };
            axios
            .post(
              process.env.VUE_APP_BACKEND_URL + "/cancelAppointment",
              appointment_body
              )
            .then((cancelAppointmentResponse) => {
              alert("Appointment Cancelled");
              this.$router.go();
            })
            .catch((cancelAppointment) => {
            });
          },
          editAppointment(appointment_id) {
            this.$router.push({
              path: "viewAppointment/" + appointment_id,
            });
          },
          filterAppointment() {
            this.isLoading = true;
            this.followUpFlag = false;
            if(this.search_type == "Appointment Status"){
               var appointmentStatus = this.appointmentStatusFilterValue.toUpperCase();
               this.paymentStatusFilterValue = '';
            }
            else if(this.search_type == "Payment Status"){
              this.appointmentStatusFilterValue = '';
            }

            // console.log("Appointment Status :", this.appointmentStatusFilterValue)
            // console.log("Payment Status :", this.paymentStatusFilterValue)
            // console.log("Search Type", this.search_type)
            if(this.nrc_number != '' || this.phone_number !=''|| this.appointmentStatusFilterValue !=''|| this.paymentStatusFilterValue != ''){
            var appointmentSearch = {
              token: this.currentUser,
              typeOfUser: "ADMIN",
              NRC: this.nrc_number,
              phoneNumber: this.phone_number,
              appointmentStatus: appointmentStatus,
              paymentStatus:this.paymentStatusFilterValue,
              page:1,
              limit:10,
            };
            axios
            .post(
              process.env.VUE_APP_BACKEND_URL + "/filterAppointments",
              appointmentSearch
              )
            .then((viewCurrentDateResponse) => {
              this.allAppointments = viewCurrentDateResponse.data.data.docs;
              this.pagination_list = viewCurrentDateResponse.data.data.pages;
              this.page = 1;
              this.allAppointments.forEach((x) =>{
                x.rescheduleCheck = ((x.status == "ACTIVE") && (x.rescheduled_appointment_id == '')) ? true : false;
                var dateNow = new Date();
                var booking_date = new Date().toISOString().split("T")[0];
                x.allowedEdit = ((x.date_of_appointment > booking_date) && (x.status == "ACTIVE")) ? true : false;
                x.class = x.configStatus
              });
              this.current_nrc = this.nrc_number;
              this.current_phone_number = this.phone_number;
              this.current_start_date = this.start_date;
              this.current_end_date = this.end_date;
              this.start_date = "";
              this.end_date = "";
              this.isLoading =false;
              this.followUpFlag = false;
            })
            .catch((viewCurrentDateError) => {
              if (viewCurrentDateError.response.status == 401) {
                this.isLoading =false
                window.alert("Sorry !! You are unauthorized !!");
                this.$router.push({
                  name: "AdminLogin",
                });
              }
              else if (viewCurrentDateError.response.status == 404)
              {
                this.isLoading = false;
                this.allAppointments = [];
                this.pagination_list = 0;
              }
              else {
                this.isLoading = false;
                window.alert("Something went wrong...");
              }
            });
            }
            else{
              this.isLoading = false;
              window.alert("Search Field can't empty!")
            }
          },
          rescheduleAppointment(appointment_id) {
            this.$router.push({
              path: "rescheduleAppointment/" + appointment_id,
            });
          },
          cancelAppointment(appointment_id, appointment_reference_number) {
            this.dialog = true;
            this.appointmentid = appointment_id;
            this.book_id = appointment_reference_number;
          },
          updatePaymentID(){
            var paymentReferanceNumberBody = {
              token: this.currentUser,
              typeOfUser: "ADMIN",
              bookingId: this.bookingIDPaymentUpdate,
              paymentReferenceNumber:this.paymentID
            };
            axios
            .post(process.env.VUE_APP_BACKEND_URL + "/updatePaymentReferenceNumberInAppointment", paymentReferanceNumberBody)
              .then(() => {
                this.paymentIDModal = false
                this.$router.go();
              })
              .catch((updatePaymentReferenceNumberInAppointmentError) => {
                console.log("Error Of payment Reference number  API : ", updatePaymentReferenceNumberInAppointmentError);
              });
          },
          openPaymentIDModal(appointment_id){
            this.bookingIDPaymentUpdate = appointment_id;
            this.paymentIDModal = true
          }
        },
};
</script>

<style scoped>
td,
th {
  border: 0px;
}
td {
  padding: 2px;
  font-size: 8pt;
  font-weight: 700;
  text-align: left;
  padding-left: 5px;
}
th {
  background-color: #1467bf;
  color: white;
  border: 0px;
  font-size: 8pt;
  font-weight: 500;
  margin-left: 10%;
  position: sticky;
  top: 0;
  z-index: 0;
  height: 40px;
  text-align: left;
  padding-left: 5px;
}
tr {
  border-bottom: #d4eeff 1px solid !important;
}
.tableClass {
  border-spacing: 0px;
  width: 100%;
  border-collapse: collapse;
  z-index: 0;
  background-color: white;
}
table > tr > td:last-child {
  white-space: nowrap;
}
.fb-customerchat{
  display: none;
}
.v-text-field.v-text-field--solo .v-input__control{
  min-height: 38px;

}
.video_join_class
{
  background-color: #1467bf;
  border-radius: 4px;
  padding: 4px;
}
.verified
{
  border-style: hidden solid hidden hidden;
  border-width: 15px;
  border-color: #46e888;
}
.Unverified
{
  border-style: hidden solid hidden hidden;
  border-width: 15px;
  border-color: #ed1d25;
}
.InProgress
{
  border-style: hidden solid hidden hidden;
  border-width: 15px;
  border-color: #e4e846;
}
.NeedAssistance
{
  border-style: hidden solid hidden hidden;
  border-width: 15px;
  border-color: grey;
}

</style>
<style>
.appointmentStatus.v-menu__content::-webkit-scrollbar-thumb{
  background-color: red  !important;

}
</style>
